.text-center {
  text-align: center;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 36;
  overflow: hidden;
  background-color: #FFFFFF;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] {
    background-color: #212121;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .modal-content {
  border: solid #212121 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zeruj::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_lista_tkanina::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_fotografia::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .bootstrap-select ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona #vwp_html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vwp_fancybox_opis_probnik::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vwp_fancybox_opis_tkanina::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zeruj::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_lista_tkanina::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_fotografia::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .bootstrap-select ul::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona #vwp_html::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vwp_fancybox_opis_probnik::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vwp_fancybox_opis_tkanina::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zeruj::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_lista_tkanina::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_fotografia::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona #vwp_html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vwp_fancybox_opis_probnik::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
#vwp_fancybox_opis_tkanina::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}

#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_produkt {
  overflow: hidden;
  z-index: 35;
}

/* START DOSTOSOWANIE KONFIGURATORA DO OKNA MODALNEGO */
.modal-backdrop {
  z-index: 34 !important;
}
.modal {
  z-index: 35 !important;
}
/* KONIEC DOSTOSOWANIE KONFIGURATORA DO OKNA MODALNEGO */

/* START PRZYCISKI W KONFIGURATORZE */
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] li {
  filter: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] button {
  border-radius: 3px !important;
  margin: 0;
  border: none !important;
  padding: 3px 6px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #loader_powiazan {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  z-index: -1;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio .select_textile {
  display: block;
  margin: 0 auto;
  height: 25px;
  width: 185px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio button {
  display: inline-block !important;
  float: right;
  margin-top: 2px;
  padding: 1px 10px;
  height: 26px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio button {
    height: 22px;
    margin-top: 3px;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio button:active {
  background-color: #F4A700 !important;
  border-color: #F4A700 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio button:focus {
  background-color: #F4A700 !important;
  border-color: #F4A700 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio .vwp_focus {
  background-color: #F4A700 !important;
  border-color: #F4A700 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn {
  font-size: 13px;
  color: #FFFFFF;
  background-color: #7b7b7a;
  border-color: #7b7b7a;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn:hover {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #C18B17;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn:focus {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #C18B17;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn:active {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #C18B17;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.active {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #C18B17;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .open .pbtn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3276b1;
  border-color: #285e8e;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn:active {
  background-image: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.active {
  background-image: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .open .pbtn.dropdown-toggle {
  background-image: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.disabled {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.disabled:hover {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.disabled:focus {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.disabled:active {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn.disabled.active {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn[disabled] {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn[disabled]:hover {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn[disabled]:focus {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn[disabled]:active {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn[disabled].active {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] fieldset[disabled] .pbtn {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] fieldset[disabled] .pbtn:hover {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] fieldset[disabled] .pbtn:focus {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] fieldset[disabled] .pbtn:active {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] fieldset[disabled] .pbtn.active {
  background-color: #428BCA;
  border-color: #357EBD;
  opacity: 0.3;
  box-shadow: none;
}
/* KONIEC PRZYCISKI W KONFIGURATORZE */
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #wybierz_detal {
  line-height: 18px;
  float: left;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
  height: 25px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #clear_configuration {
  float: right;
  margin: 1px 10px 1px 0;
  padding: 2px 12px 0 12px;
  height: 23px;
  font-weight: 400 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #automatyczna_konfiguracja_label {
  float: right;
  margin: 0;
  padding: 4px 10px 1px 10px;
  height: 25px;
  font-weight: 400 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #automatyczna_konfiguracja_label #automatyczna_konfiguracja {
  margin: -2px 5px 0 0;
  height: 23px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #automatyczna_konfiguracja_label span {
  cursor: pointer;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj {
  display: none;
  float: right;
  padding: 2px 12px 0 0;
  height: 25px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj {
    display: block;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj input {
  width: 150px;
  height: 22px !important;
  border-radius: 0 !important;
  border-right-width: 0 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj button {
  margin: 0;
  border-radius: 0;
  height: 22px;
  background-color: #F4A700;
  font-weight: 400!important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj button:before {
  content: "\f002";
  display: block;
  font-family: "FontAwesome";
  font-size: 12px;
  width: 100%;
  text-align: center;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj button:hover {
  background-color: #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #wybierz_wykonczenie {
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
  height: 25px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zeruj {
  padding: 0 !important;
  margin: 0  !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_mebel {
  border: solid #D6D4D4 1px;
  height: 620px;
  overflow: hidden;
  margin: 0;
  border: solid 0 #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .konfigurator_przyciski {
  text-align: center !important;
  width: 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zamknij {
  background-color: #B3B3B3;
  color: #FFFFFF;
  outline: solid 0 #B3B3B3;
  border-radius: 0 !important;
  line-height: 18px !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zamknij i {
  font-size: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zamknij i:before {
  content: "\f00d";
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_dalej {
  width: 100%;
  line-height: 18px !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_dalej i {
  font-size: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_dalej i:before {
  content: "\f054";
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zamknij:hover {
  background-color: #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_dalej:hover {
  background-color: #212121;
}
@media (max-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zamknij i {
    font-size: 13px;
  }
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_dalej i {
    font-size: 13px;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_kontener.scal {
  float: left;
}




.fancybox_wzorniki #fancybox_nawigacja {
  position: fixed;
  top: 0;
  left: 0;
  height: 44px;
  width: 100%;
  z-index: 50;
}
.fancybox_wzorniki #fancybox_nawigacja > div {
  display: none;
  float: left;
  margin: 7px 3px;
  width: calc(100% - 128px);
}
.fancybox_wzorniki.mobile #fancybox_nawigacja > div {
  width: calc(100% - 94px);
}
.fancybox_wzorniki #fancybox_nawigacja > div #vwp_fancybox_opis {
  float: left;
  margin: 0 0 0 0;
  height: 33px;
}
.fancybox_wzorniki #fancybox_nawigacja > div #vwp_fancybox_opis.none {
  display: none !important;
}
.fancybox_wzorniki #fancybox_nawigacja > div #vwp_fancybox_button {
  float: left;
  margin: 0 0 0 0;
  border: 1px solid transparent;
  padding: 6px 12px 6px 12px;
  height: 33px;
  font-size: 13px;
}
.fancybox_wzorniki #fancybox_nawigacja > div #vwp_fancybox_button.none {
  display: none !important;
}
.fancybox_wzorniki #fancybox_nawigacja #vwp_fancybox_zamknij {
  display: none !important;
  position: fixed;
  right: 9px;
  bottom: 3px;
  margin: 0 0 0 0;
  border-color: #F4A700;
  padding: 6px 30px;
  height: 33px;
  background-color: #F4A700;
  color: #FFFFFF;
  cursor: pointer;
}
.fancybox_wzorniki #fancybox_nawigacja #vwp_fancybox_zamknij.none {
  display: none !important;
}
@media (max-width: 768px) {
  .fancybox_wzorniki #fancybox_nawigacja #vwp_fancybox_zamknij {
    display: block !important;
  }
}
.fancybox_wzorniki #fancybox_nawigacja #fancybox_button_nawigacja_left {
  float: left;
  margin: 7px 0 7px 0;
  padding: 5px 0 5px 1px;
  width: 40px;
  height: 30px;
  background-color: transparent;
}
.fancybox_wzorniki #fancybox_nawigacja #fancybox_button_nawigacja_left[disabled] {
  visibility: hidden;
}
.fancybox_wzorniki #fancybox_nawigacja #fancybox_button_nawigacja_left::after {
  font-family: "FontAwesome";
  content: "\f137";
  padding: 0 0 0 2px;
  color: #F4A700;
  font-size: 22px;
}
.fancybox_wzorniki #fancybox_nawigacja #fancybox_button_nawigacja_right {
  float: left;
  margin: 7px 0 7px 0;
  padding: 5px 0 5px 1px;
  width: 40px;
  height: 30px;
  background-color: transparent;
}
.fancybox_wzorniki #fancybox_nawigacja #fancybox_button_nawigacja_right[disabled] {
  visibility: hidden;
}
.fancybox_wzorniki #fancybox_nawigacja #fancybox_button_nawigacja_right::after {
  font-family: "FontAwesome";
  content: "\f138";
  padding: 0 0 0 2px;
  color: #F4A700;
  font-size: 22px;
}


.fancybox_wzorniki .fancybox-caption {
  padding: 15px 10px 0 10px;
  line-height: 18px;
}
.fancybox_wzorniki .fancybox-caption .fancybox-caption__body {
  border-top: 1px solid rgba(255,255,255,0.4);
  padding: 11px 0;
  min-height: 41px;
  text-align: left;
}
.fancybox_wzorniki.mobile .fancybox-caption .fancybox-caption__body {
  overflow: hidden;
  height: 41px;
  line-height: 23px;
}



.fancybox_wzorniki .fancybox-thumbs {
  background-color: #FFFFFF;
}
.fancybox_wzorniki .fancybox-thumbs > ul {
  overflow: auto;
  width: 209px;
  height: 100%;
}
.fancybox_wzorniki.mobile .fancybox-thumbs > ul {
  width: 108px;
}
.fancybox_wzorniki .fancybox-thumbs > ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #EAEAEA;
}
.fancybox_wzorniki .fancybox-thumbs > ul::-webkit-scrollbar {
  width: 7px;
  background-color: #EAEAEA;
}
.fancybox_wzorniki .fancybox-thumbs > ul::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 3px rgba(0,0,0,.3);
  background-color: #2A2A2A;
}
.fancybox_wzorniki .fancybox-thumbs > ul > li {
  position: relative;
  left: 0;
  bottom: 0;
  overflow: hidden;
  float: left;
  margin: 2px;
  width: 97px;
  height: 97px;
}
.fancybox_wzorniki .fancybox-thumbs > ul > li.fancybox-thumbs-active {
  border: 2px solid #4EA7F9;
}
.fancybox_wzorniki .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #4EA7F9;
  z-index: 42;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.fancybox_wzorniki .fancybox-thumbs > ul > li > img {
  position: relative;
  right: 0;
  top: 0;
}
.fancybox_wzorniki .fancybox-caption-wrap {
  margin: 0;
  padding: 15px 10px 42px 10px;
}
@media (min-width: 768px) {
  .fancybox_wzorniki .fancybox-caption-wrap {
    padding: 15px 10px 0 10px;
  }
}
.fancybox_wzorniki .fancybox-caption-wrap .fancybox-caption {
  margin: 0;
  padding: 10px 0;
}







#vwp_fancybox_opis_probnik {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%; /*calc(100% - 20px);*/
  width: 100%; /*calc(100% - 20px);*/
  padding: 10px;
  color: #212121;
  background-color: #FFFFFF;
  z-index: 45;
  overflow: auto;
}
#vwp_fancybox_opis_tkanina {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%; /*calc(100% - 20px);*/
  width: 100%; /*calc(100% - 20px);*/
  padding: 10px;
  color: #212121;
  background-color: #FFFFFF;
  z-index: 45;
  overflow: auto;
}
#vwp_fancybox_opis_probnik i {
  display: block;
  float: right;
  font-size: 18px !important;
  margin: 1px 1px 0 0;
  width: 24px;
  height: 24px;
  background: url('/img/close2.png') no-repeat;
  background-size: 24px;
  cursor: pointer;
}
#vwp_fancybox_opis_probnik i:hover {
  opacity: 0.7 !important;
}
#vwp_fancybox_opis_tkanina i {
  display: block;
  float: right;
  font-size: 18px !important;
  margin: 1px 1px 0 0;
  width: 24px;
  height: 24px;
  background: url('/img/close2.png') no-repeat;
  background-size: 24px;
  cursor: pointer;
}
#vwp_fancybox_opis_tkanina i:hover {
  opacity: 0.7 !important;
}
#vwp_fancybox_opis_probnik output img {
  width: 100%;
}
#vwp_fancybox_opis_tkanina output img {
  width: 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image {
  display: block;
  padding: 0;
}
@media (max-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image {
    float: left;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_fotografia {
  height: calc(100% - 85px);
  padding: 5px 0 0 0;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_fotografia {
    overflow: auto;
    padding: 0;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz {
  display: none;
  height: 60px;
  padding: 0 12px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz {
    display: block;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz #konfigurator_przyciski {
  text-align: left !important;
  height: 32px;
  padding: 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz #konfigurator_przyciski div {
  padding: 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz #konfigurator_przyciski div button {
  border-radius: 0;
  height: 32px;
  font-size: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #img_cena_zatwierdz #konfigurator_przyciski div button span {
  font-weight: 600;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image img:hover {
  cursor:zoom-in;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image img {
  margin: 0 auto;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image p {
  text-align: center;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image #vwp_img3 p {
  text-align: left;
  line-height: 18px;
}
#vwp_fancybox_opis_probnik output p {
  text-align: left;
  line-height: 18px;
}
#vwp_fancybox_opis_tkanina output p {
  text-align: left;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image #vwp_img3 ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
#vwp_fancybox_opis_probnik output ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
#vwp_fancybox_opis_tkanina output ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image #vwp_img3 ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
#vwp_fancybox_opis_probnik output ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
#vwp_fancybox_opis_tkanina output ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image #vwp_img3 li {
  display: list-item;
  line-height: 18px;
}
#vwp_fancybox_opis_probnik output li {
  display: list-item;
  line-height: 18px;
}
#vwp_fancybox_opis_tkanina output li {
  display: list-item;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img4 {
  width: 100%;
  height: 50px;
  padding: 5px 0;
  color: #F4A700;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img6 {
  visibility: hidden;
  font-size: 25px;
  font-weight: bold;
  float: left;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 {
  font-size: 18px;
  font-weight: bold;
  float: right;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img7 {
  float: left;
  width: 100%;
  height: 25px;
  padding: 4px 12px 0 12px;
  color: #F4A700;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img9 {
  visibility: hidden;
  font-size: 18px;
  font-weight: bold;
  float: left;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 {
  font-size: 18px;
  font-weight: bold;
  float: right;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 .cena_stara {
  font-size: 20px;
  font-weight: bold;
  color: #212121;
  font-weight: bold;
  text-decoration-line: line-through;
  padding: 0px 0px 0px 0px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_stara {
  font-size: 20px;
  font-weight: bold;
  color: #212121;
  font-weight: bold;
  text-decoration-line: line-through;
  padding: 0px 0px 0px 0px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 .cena_rabat {
  font-size: 18px;
  font-weight: bold;
  color: red;
  font-weight: bold;
  padding: 0px 0px 0px 10px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_rabat {
  font-size: 18px;
  font-weight: bold;
  color: red;
  font-weight: bold;
  padding: 0px 0px 0px 10px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 .cena_nowa {
  font-size: 25px;
  font-weight: bold;
  color: #F4A700;
  font-weight: bold;
  padding: 0px 0px 0px 10px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_nowa {
  font-size: 25px;
  font-weight: bold;
  color: #F4A700;
  font-weight: bold;
  padding: 0px 0px 0px 10px;
}
@media (max-width: 980px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 .cena_stara,
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_stara,
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 .cena_rabat,
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_rabat {
    font-size: 16px;
    padding: 0px 0px 0px 5px;
  }
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img5 .cena_nowa,
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_nowa {
    font-size: 20px;
    padding: 0px 0px 0px 5px;
  }
}
@media (max-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_stara,
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_rabat {
    font-size: 13px;
  }
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_img8 .cena_nowa {
    font-size: 18px;
  }
}

#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image #img_fotografia #vwp_img2 {
  padding: 0 12px;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_image #img_fotografia #vwp_img3 {
  padding: 0 12px;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator {
  float: left;
  padding: 0 0 0 12px !important;
  margin: 0 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_lista_tkanina {
  overflow: auto;
  float: left;
  padding: 0 0 0 12px !important;
  margin: 0 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 {
  display: none;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 {
    display: block;
    overflow: hidden;
    padding: 4px 0px 4px 4px;
    clear: both;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja1 {
  float: left;
  padding-bottom: 5px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja2 {
  overflow: hidden;
  padding: 4px 0px 4px 4px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik {
  height: 43px;
  padding: 0 0 5px 0;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik {
    height: 43px;
    padding: 4px 0px 4px 4px;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 ul li {
  float: left;
  border: solid #D6D4D4 1px;
  height: 34px;
  padding: 0 5px 0 0;
  margin: 0 4px 6px 0 !important;
}

/* START TELEFON */
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select {
  float: left;
  position: relative;
  margin: 0 0 0 0 !important;
  border: solid #D6D4D4 0;
  padding: 0 0 0 0;
  height: 35px;
  background-color: #FFFFFF;
  outline: none !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select .dropdown-toggle {
  position: relative;
  float: left;
  margin: 0 0 0 0 !important;
  border: solid #D6D4D4 1px !important;
  border-radius: 0;
  padding: 0 0 0 0;
  height: 35px;
  font-size: 13px;
  outline-width: 0 !important;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select .dropdown-toggle:active {
  background-color: #FFFFFF;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select .dropdown-toggle:hover {
  background-color: #FFFFFF;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik {
  float: left;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja1 .bootstrap-select button span:first-child {
  padding: 8px 0 8px 8px;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik .bootstrap-select button span:first-child {
  padding: 8px 0 8px 8px;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja1 .bootstrap-select ul li a span {
  padding: 11px 0 11px 8px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik .bootstrap-select ul li a span {
  padding: 11px 0 11px 8px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select div.dropdown-menu {
  float: left;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  margin: -1px 0 0 0;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0;
  padding: 3px 0 0 0 !important;
  width: 100%;
  min-width: 160px;
  list-style: none;
  font-size: 13px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-clip: padding-box;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select div.dropdown-menu {
  display: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select.open div.dropdown-menu {
  display: block;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfigurator .bootstrap-select ul li a {
  display: inline-block;
  height: 40px !important;
  width: 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #przyciski_cen {
  display: block;
  float: left;
  padding: 0;
  margin: 0;
  height: 60px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #przyciski_cen {
    display: none;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #przyciski_cen button {
  height: 35px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button span {
  padding: 5px 0 0 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button span sup {
  float: left;
  padding: 4px 0 0 5px;
  font-weight: bold;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button output {
  width: 22px;
  height: 22px;
  float: left;
  margin: 0 3px 0 5px;
  background-size: 100% 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button output.konfigurator_niewybrany {
  background-image: url('/img/konfigurator_niewybrany.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 ul output.konfigurator_niewybrany {
  background-image: url('/img/konfigurator_niewybrany.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 output.konfigurator_niewybrany {
  background-image: url('/img/konfigurator_niewybrany.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button output.konfigurator_wybrany {
  background-image: url('/img/konfigurator_wybrany.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 ul output.konfigurator_wybrany {
  background-image: url('/img/konfigurator_wybrany.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 output.konfigurator_wybrany {
  background-image: url('/img/konfigurator_wybrany.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button output.konfigurator_wykluczony {
  background-image: url('/img/konfigurator_wykluczony.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 ul output.konfigurator_wykluczony {
  background-image: url('/img/konfigurator_wykluczony.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 output.konfigurator_wykluczony {
  background-image: url('/img/konfigurator_wykluczony.png');
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 button p {
  margin: 4px 10px 0 0;
  float: left;
  font-size: 13px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 sup {
  float: left;
  padding: 5px 0 0 5px;
  font-weight: 700;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 ul .vwp_aktywny_1 sup {
  color: #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 ul output {
  width: 22px;
  height: 22px;
  float: left;
  margin: 7px 3px 0 5px;
  background-size: 100% 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 ul p {
  float: left;
  margin: 10px 10px 0 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_fancybox {
  display: none;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn .caret {
  margin-left: 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .pbtn .caret {
  border-top-color: #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  border-top: 4px solid #212121;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 0 dotted;
  content: "";
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}







/* KONIEC TELEFON */

#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja2 ul li {
  float: left;
  border: solid #D6D4D4 1px;
  height: 27px;
  min-width: 50px;
  margin: 0 4px 6px 0 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik2 ul li {
  float: left;
  border: solid #D6D4D4 1px;
  height: 30px;
  min-width: 50px;
  margin: 0 4px 6px 0 !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_31 {
  height: 380px;
  overflow: auto;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 ul li:hover {
  cursor: pointer;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja2 ul li:hover {
  cursor: pointer;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik2 ul li:hover {
  cursor: pointer;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_input p {
  float: left;
  padding: 8px 0 0 0;
  width: 25%;
  height: 31px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_input input {
  width: 32%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_input select {
  width: 20%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_input button {
  margin: 0 0 0 3px;
  height: 31px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_select {
  width: 100%;
  min-width: 350px;
  height: 31px;
  margin: 0 0 5px 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_select p {
  float: left;
  padding: 8px 0 0 0;
  /*width: 25%;*/
  height: 31px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_select select {
  width: 70%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio {
  position: relative;
  color: #212121;
  float: left;
  border-radius: 2px;
  border: solid 0 #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px, #F4A700 0px 0px 0px 4px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio img.polygon {
  /* padding: 5px;*/
  /*Chrome,Safari*/
  clip-path: polygon(83px 1px,77px 7px,72px 2px,66px 7px,61px 2px,55px 7px,50px 2px,44px 7px,39px 2px,33px 7px,28px 2px,22px 7px,17px 2px,11px 7px,6px 2px,1px 6px,6px 13px,1px 18px,7px 23px,2px 29px,6px 34px,1px 39px,7px 45px,2px 51px,6px 56px,1px 61px,7px 67px,1px 72px,7px 78px,2px 83px,7px 88px,1px 94px,6px 98px,12px 93px,18px 99px,23px 93px,28px 98px,34px 93px,39px 99px,44px 93px,50px 98px,56px 94px,61px 98px,66px 93px,72px 98px,77px 93px,83px 99px,88px 93px,94px 99px,98px 94px,93px 88px,98px 83px,93px 78px,98px 72px,93px 67px,98px 61px,93px 56px,98px 50px,93px 45px,98px 39px,93px 34px,98px 28px,93px 23px,98px 17px,93px 12px,98px 7px,94px 2px,88px 7px);
  /*width: 100% !important;*/
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio img:hover {
  cursor: zoom-in;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio p {
  text-align: center;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio .vwp_tkanina {
  display: list-item;
  height: 15px;
  line-height: 18px;
}

#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_lewy {
  float: left;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 ul li sup {
  float: left;
  padding: 5px 0 0 5px;
  font-weight: bold;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 ul li output {
  width: 30px;
  height: 30px;
  float: left;
  margin: 0 5px 0 5px;
  background-size: 100% 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha2 ul li p {
  margin: 7px 10px 0 0;
  float: left;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja2 ul li p {
  margin: 5px 10px 0 10px;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik2 ul li p {
  margin: 7px 10px 0 10px !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_grupa {
  font-size: 20px;
  height: 30px;
  width: 100%;
  padding-top: 5px;
  clear: both;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_kontener .vwp_grupa strong {
  font-weight: bold;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_probnik {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 2px 0;
  height: 30px;
  width: 100%;
  padding: 5px 0 0 0;
  clear: both;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_probnik > span {
  display: block;
  min-height: 23px;
  max-width: calc(100% - 28px);
  float: left;
  margin: 0 5px 0 0;
  padding-top: 3px;
  line-height: 18px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_probnik button {
  display: block;
  margin-right: 5px;
  height: 26px;
  /*width: 18px;*/
  float: left;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_mebel select {
  display: block;
  float: left;
  height: 31px;
  padding: 6px 8px;
  margin: 0;
  font-size: 13px;
  line-height: 18px; /*1.42857;*/
  color: #555;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #C7D6DB;
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_mebel input {
  display: block;
  float: left;
  height: 31px;
  padding: 6px 8px;
  margin: 0;
  font-size: 13px;
  line-height: 18px; /*1.42857;*/
  color: #555;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #C7D6DB;
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_infos {
  display: block;
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 1;
  padding: 1px;
  border-radius: 3px / 3px;
  line-height: 18px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_infos {
    top: 10px;
    border-radius: 0 / 0;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_info {
  color: #FFFFFF;
  background-color: #7b7b7a;
  border-color: #7b7b7a;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 15px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_info {
    padding: 0 4px;
    font-size: 13px;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_info:hover {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #E4A316;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_infos2 {
  display: block;
  float: left !important;
  z-index: 1;
  padding: 1px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_info2 {
  color: #FFFFFF;
  background-color: #7b7b7a;
  border-color: #7b7b7a;
  /*padding: 4px 8px;*/
  cursor: pointer;
  font-size: 15px;
}
/*
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_info2 {
    padding: 0 4px;
	font-size: 13px;
  }
}
*/
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_infos2:hover {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #E4A316;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_infos2:hover .radio_info2 {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #E4A316;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .radio_info2:hover {
  color: #FFFFFF;
  background-color: #E4A316;
  border-color: #E4A316;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_ukryj {
  display: none !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_aktywny_1 {
  /* wybrane grupy cech */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px, #F4A700 0px 0px 0px 3px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 .bootstrap-select ul li {
  margin: 0px 0px 2px 0px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 .bootstrap-select ul li .vwp_aktywny_1 span {
  margin: 0px 0px 0px -3px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_aktywny_2 {
  /* wybrane grupy kombinacji */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px, #F4A700 0px 0px 0px 3px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] a.vwp_aktywny_3:not(.vwp_nieaktywny_3) {
  /* wybrane grupy próbników */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px, #F4A700 0px 0px 0px 3px;
}

#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_cecha1 .bootstrap-select ul li .vwp_aktywny_1,
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_kombinacja1 .vwp_aktywny_2,
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik a.vwp_aktywny_3:not(.vwp_nieaktywny_3) {
  /* wybrane grupy cech/kombinacji/próbników select */
  margin: 0px 0px 0px 3px !important;
  width: calc(100% - 6px) !important;
  height: 37px !important;
  color: #212121;
}

#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_aktywny_4:not(.vwp_select):not(.vwp_input) {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px, #F4A700 0px 0px 0px 4px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_aktywny_5 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px, #F4A700 0px 0px 0px 4px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_nieaktywny_3 {
  background-color: transparent !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zablokowany {
  display: block !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zablokowany_4:not(.vwp_select):not(.vwp_input):not(.vexon_szukaj):not(.hide) {
  display: block !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zablokowany_5 {
  display: block !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vexon_szukaj {
  display: none !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio.vwp_zablokowany_4 .radio_infos {
  filter: grayscale(100%) !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio.vwp_zablokowany_4 .radio_infos2 {
  filter: grayscale(100%) !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio.vwp_zablokowany_4 img {
  filter: grayscale(100%) !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio.vwp_zablokowany_4 p {
  filter: grayscale(100%) !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio .vwp_blokada {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
  height: 100%;
  width: 100%;
  background: #F3F3F3;
  opacity: 0.75;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio .vwp_blokada2 {
  position: absolute;
  top: calc(50% - 18px);
  left: 0;
  z-index: 3;
  display: none;
  height: 100%;
  width: 100%;
  color: #212121;
  text-align: center;
  font-size: 15px;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_radio .vwp_blokada2 {
    font-size: 13px;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zablokowany_4.vwp_radio .vwp_blokada {
  display: block;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zablokowany_4.vwp_radio .vwp_blokada2 {
  display: block;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik .bootstrap-select ul li:hover {
  background-color: #428BCA !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #lista_probnik .bootstrap-select ul li a:hover {
  background-color: #428BCA !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_szukaj_brak {
  padding-top: 20px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 36;
  background-color: #FFFFFF;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona {
    position: fixed;
    top: 10px;
    left: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona #vwp_html {
  width: 100%;
  overflow: auto;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona #vwp_html > div {
    padding: 0;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .txt_black {
  color: #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .txt_black .title {
  margin: 0;
  padding: 15px 0;
  text-align: center;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .txt_black .title .icon-cogs {
  font-size: 150%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .txt_black .title .icon-cogs:before {
  content: "\f085";
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .content {
  padding-left: 0;
}
@media (min-width: 992px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .content {
    padding-left: 23.5%;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .content span {
  font-size: 16px;
  font-weight: 700;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .content .quotation-vexon ul li {
  filter: none;
  height: 40px !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .content .quotation-vexon ul li img {
  float: left !important;
  margin: 0 5px 0 0 !important;
  padding: 0 0 0 0 !important;
  width: 40px !important;
  height: 40px !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .content .quotation-vexon ul li p {
  font-size: 16px;
  margin: 11px 0 0 0 !important;
  width: calc(100% - 45px);
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price {
  padding: 0 15px;
  height: 82px;
  width: 100%;
  text-align: center;
  color: #F4A700;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price {
    padding: 0;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price h3 {
  margin: 0;
  font-size: 18px;
  float: right;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price .cena_nazwa {
  display: block;
  float: left;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.2em;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price .cena_stara {
  float: right;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2em;
  color: #212121;
  text-decoration-line: line-through;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price .cena_rabat {
  float: right;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2em;
  color: red;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .price .cena_nowa {
  float: right;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.2em;
  color: #F4A700;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container {
  height: 37px;
  padding: 5px 0 0 0;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container {
    height: 47px;
    padding: 5px 0 10px 0;
  }
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div {
  padding: 0;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div a {
  height: 32px;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .powrot {
  margin: 0 0 0 0;
  width: 100%;
  /*
  background-color: #B3B3B3;
  color: #FFFFFF;
  */
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .powrot:hover {
  background: #212121;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .vwp_zamknij {
  margin: 0 0 0 0;
  width: 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .nowy_koszyk {
  margin: 0 0 0 0;
  width: 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .nowa_wycena {
  margin: 0 0 0 0;
  width: 100%;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .vwp_zamknij:hover {
  /*
  background: #212121;
  */
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .nowy_koszyk:hover {
  /*
  background: #212121;
  */
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container div .nowa_wycena:hover {
  /*
  background: #212121;
  */
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container a span {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 35px !important;
  border-radius: 0 !important;
  text-align: center !important;
  margin: 0 auto !important;
  width: 100% !important;
  height: 32px;
  letter-spacing: 0.5px !important;
  padding: 0 0 0 0 !important;
  color: #fff !important;
}
#vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container a span i {
  font-size: 18px !important;
}
@media (max-width: 768px) {
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container a span {
    font-size: 14px !important;
  }
  #vexon_wzorniki_konfigurator[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #konfigurator_przeslona .button-container a span i {
    font-size: 14px !important;
  }
}
/* START OKNO MODALNE Z PRZYCISKIEM "KONFIGURUJ" */
#vwp_box_styl[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] {
  text-align: center;
  background: #FFFFFF;
  border: 0 solid #D2D0D0;
  padding: 0 0 15px 0;
}
#vwp_box_styl[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] p {
  width: 500px;
}
#vwp_box_styl[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfiguruj {
  margin: 0 auto;
}
#vwp_box_styl[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfiguruj:before {
  content: ""; /* \f044 */
}
#vwp_box_styl[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_konfiguruj span {
  text-align: center;
}
/* KONIEC OKNO MODALNE Z PRZYCISKIEM "KONFIGURUJ" */
