.vexon_lista_podsumowanie[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_zamow:before {
  content: ''; /*"\f044";*/
}
.vexon_lista_podsumowanie[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #vwp_zamow span {
  text-align: center;
}
.vexon_lista_podsumowanie[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] .vwp_zeruj {
  padding: 0px !important; 
  margin: 0px !important;
}
.vexon_lista_podsumowanie[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] {
  padding: 0px; 
  margin: 0px;
  text-align: left;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany {
  padding: 0px !important; 
  margin: 0px !important;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany {
  height: 90px;
  margin: 0px;
  padding: 0px;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany .section {
  float: left;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany .skonfigurowany_img img, 
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany .skonfigurowany_img div {
  width: 65px; 
  height: 65px;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany .skonfigurowany_img img {
  cursor: zoom-in;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany .skonfigurowany_cena {
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany .section_przyciski {
  float: right;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany_lista {
  width: 100%;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #skonfigurowany .skonfigurowany_konfigurator {
  display: none;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany {
  overflow: hidden;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator {
  overflow: auto;
}

#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator,
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator p {
  color: #212121;
  text-align: left;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator .skonfigurowany_lewy {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator .skonfigurowany_prawy {
  float: right;
  padding-top: 10px;
  padding-left: 10px;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator .skonfigurowany_img img, 
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_konfigurator .skonfigurowany_img div {
  width: 165px; 
  height: 165px;
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany .skonfigurowany_html {
  
}
#produkt_skonfigurowany[data-szablon="meble_tapicerowane_narozniki_sofy_fotele_lozka"] #bootstrap_skonfigurowany #skonfigurowany_zamknij {
  position: absolute;
  top: 7px;
  right: 10px;
  display: none;
}