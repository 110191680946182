/* START BLOK WYCENY ADMIN */
.vwp_wycena_box {
	
}
.vwp_wycena_box ul {
  list-style: none; 
  border-bottom: solid 0px white; 
  list-style-type: none;
  list-style-position: initial;
  list-style-image: initial;
  padding: 0px;
  margin: 0px;
}
.vwp_wycena_box li {
  list-style: none;
}
.vwp_wycena_box li:hover {
  background-color: rgb(244, 248, 251);
}
/* KONIEC BLOK WYCENY ADMIN */


/* START PODSUMOWANIE WYCENY W MODULE QUOTATION OD STRONY KLIENTA */
.quotation-vexon {
  padding: 15px 0px 0px 0px;
}
.quotation-vexon ul {
  list-style-type: none; 
  padding-left: 0px;
}
.quotation-vexon ul li {
  padding: 0px !important; 
  margin: 0px 0px 3px 0px !important; 
  float: left;
}
.quotation-vexon ul li > img {
  width: 40px; 
  height: 40px; 
  float: left; 
  margin: 0px 5px 0px 0px !important;
}
.quotation-vexon ul li > div {
  width: 40px; 
  height: 40px; 
  float: left; 
  margin: 0px 5px 0px 0px !important;
}
.quotation-vexon ul li > p {
  float: left; 
  margin: 11.335px 0px 0px 0px;
}

.layer_cart_product_info {
  clear: both;
} 
/* okno modalne na stronie produktu po wybraniu wyceny */
.cart_delete a.cart_quantity_vexon_delete {
  font-size: 23px; 
  color: #212121;
}
#cart_summary .cart_item .cart_quantity_button button {
  float: left; 
  clear: left;
}
/* KONIEC PODSUMOWANIE WYCENY W MODULE QUOTATION OD STRONY KLIENTA */

/* START PODSUMOWANIE */
.configure_non_product_page.vexon_lista_podsumowanie #vwp_zamow:before {
  content: ''; /*"\f044";*/
}
.configure_non_product_page.vexon_lista_podsumowanie #vwp_zamow span {
  text-align: center;
}
.configure_non_product_page.vexon_lista_podsumowanie .vwp_zeruj {
  padding: 0px !important; 
  margin: 0px !important;
}
.configure_non_product_page.vexon_lista_podsumowanie, 
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie {
  padding: 0px; 
  margin: 0px;
  text-align: left;
}
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie button {
  float: right;
}
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie p {
  color: #212121;
}
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie ul li {
  margin: 5px 0px; 
  list-style: none;
}
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie ul li {
  margin: 0px 0px; 
  float: left;
  min-height: 51px;
  max-height: 52px;
  width: 100%;
}
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie ul li table, 
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie ul li table tbody, 
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie ul li table tr, 
.configure_non_product_page.vexon_lista_podsumowanie .vwp_podsumowanie ul li table tr td {
  min-height: 51px !important;
  max-height: 52px !important;
  margin: 0px !important; 
  padding: 0px !important; 
}
.vwp_wycena_box .vwp_podsumowanie ul li table tr td:nth-child(2) {
  padding-left: 5px !important;
}

/* KONIEC PODSUMOWANIE */