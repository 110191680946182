#vexon_wzorniki_konfigurator {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
}
#vexon_wzorniki_konfigurator span {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
}
#vexon_wzorniki_konfigurator h1 {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator h2 {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator h3 {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator p {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator a {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator img {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator strong {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator sup {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator ul {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator li {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator output {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
}
#vexon_wzorniki_konfigurator section {
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 18px;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: none;
  outline: 0;
} 
#vexon_wzorniki_konfigurator section {
  display: block;
} 
#vexon_wzorniki_konfigurator a {
  text-decoration: none;
} 
#vexon_wzorniki_konfigurator ul {
  list-style: none;
  clear: none;
}
#vexon_wzorniki_konfigurator h1 {
  font-weight: normal;
} 
#vexon_wzorniki_konfigurator h2 {
  font-weight: normal;
} 
#vexon_wzorniki_konfigurator h3 {
  font-weight: normal;
} 
#vexon_wzorniki_konfigurator strong {
  font-weight: normal;
}
#vexon_wzorniki_konfigurator i {
  font-style: normal;
} 
#vexon_wzorniki_konfigurator button {
  margin: 0px; 
  padding: 0px; 
  border: 0px;
  border-radius: 0px;
  text-transform: none;
}
#vexon_wzorniki_konfigurator {
  filter: none !important; 
} 
#vexon_wzorniki_konfigurator #vwp_produkt li {
  filter: none !important; 
}
#vexon_wzorniki_konfigurator h1 { 
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1.2em;
}
#vexon_wzorniki_konfigurator h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1.2em;
}
#vexon_wzorniki_konfigurator h3 { 
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1.2em;
}
#vexon_wzorniki_konfigurator li {
  display: list-item;
}
#vexon_wzorniki_konfigurator sup { 
  vertical-align: super;
  font-size: smaller;
}
#vexon_wzorniki_konfigurator strong {
  font-weight: bold;
}